<template>
    <div class="setGoodsListPage">
        <Header seet="4" />
        <el-row type="flex">
            <el-col :span="3" ></el-col>
            <el-col :span="17" style="z-index: 3;">
                <div class="breadcrumb-box">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item>商品设置</el-breadcrumb-item>
                        <el-breadcrumb-item>商品列表</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="content-box">
                    <LeftHurdle style="margin-right: 20px" />
                    <div class="box-rights">
                        <div class="box-right-top">商品列表</div>
                        <div class="from-box">
                            <el-form 
                                ref="headForm"
                                :model="headForm"
                                label-width="90px"
                                :inline="true"
                                size="small"
                                @submit.native.prevent
                            >
                                
                                <el-form-item label="商品skuID:" prop="externalSkuCode">
                                    <el-input v-model.trim="headForm.externalSkuCode" placeholder="请填写商品skuID" clearable style="width: 190px"></el-input>
                                </el-form-item>
                                <el-form-item label="商品名称:" prop="goodsAlias">
                                    <el-input v-model.trim="headForm.goodsAlias" placeholder="请填写商品名称" clearable></el-input>
                                </el-form-item>
                                
                                <el-form-item style="margin-bottom: 0px">
                                    <div class="button">
                                        <el-button type="success" class="search-btn" native-type="submit" @click="onSeach()">查询</el-button>
                                        <!-- <div class="search-btn" native-type="submit" @click="onSeach()">查询</div> -->
                                        <div class="export-btn" :class="{ notAllowed: onExportBtn }" @click="onExport()">
                                            <i v-if="onExportBtn" class="el-icon-loading"></i>
                                            导出
                                        </div>
                                        <div class="export-btn" style="width: 88px" @click="onImport()">{{importBtnText}}</div>
                                        <input ref="importFile" type="file" name="" style="display: none" @change="onImportChange" />
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="table-box">
                            <el-table ref="goodsTable" v-loading="tableLoading"
                                :data="tableData" style="width: 100%" border
                                :header-cell-style="{'background-color': '#ccc','color': '#000000d9'}"
                            >
                                <el-table-column prop="skuId" label="原商品skuID" align="center" min-width="120"></el-table-column>
                                <el-table-column prop="externalSkuCode" label="商品skuID" align="center" min-width="120"></el-table-column>
                                <el-table-column prop="goodsName" label="原商品名称" align="center" min-width="160"></el-table-column>
                                <el-table-column prop="goodsAlias" label="商品名称" align="center" min-width="160"></el-table-column>
                                <el-table-column prop="skuPic" label="缩略图" align="center" width="110">
                                    <template slot-scope="scope">
                                        <el-image 
                                            fit="scale-down"
                                            style="width: 80px; height: 80px"
                                            :src="scope.row.skuPic" 
                                            :preview-src-list="[scope.row.skuPic]">
                                        </el-image>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="specValue" label="规格" align="center" width="160"></el-table-column>
                                <el-table-column 
                                    prop="status" 
                                    align="center" 
                                    label="商品状态" 
                                    width="100"
                                >
                                    <template slot-scope="scope">
                                        <span>{{goodsStatusFn(scope.row.status)}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    fixed="right"
                                    label="操作"
                                    align="center"
                                    width="120">
                                    <template slot-scope="scope">
                                        <el-button type="warning" v-if="scope.row.status == 1" @click="setGoodstn(scope.row)" size="mini">设置商品</el-button>
                                        <el-button type="warning" v-else disabled size="mini">设置商品</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="tablePage">
                                <el-pagination
                                    background
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="currentPage"
                                    :page-sizes="[10, 20, 50, 100]"
                                    :page-size="pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="total"
                                    popper-class="popperPage">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </el-col>
            <el-col :span="3" ></el-col>
        </el-row>

        <el-dialog 
            title="设置商品"
            :visible.sync="dialogFormVisible"
            width="450px"
            modal-append-to-body
            :close-on-click-modal="false"
            @close="handleClose"
        >
            <el-form ref="dialogForm" :model="dialogForm" label-width="100px" label-position="right" size="small" :rules="rules" @submit.native.prevent>
                <el-form-item label="商品skuID:" prop="externalSkuCode">
                    <el-input v-model.trim="dialogForm.externalSkuCode" placeholder="请填写商品skuID" clearable></el-input>
                    <!-- <el-input v-model.trim="dialogForm.externalSkuCode" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请填写商品skuID" clearable></el-input> -->
                </el-form-item>
                <el-form-item label="商品名称:" prop="goodsAlias">
                    <el-input v-model.trim="dialogForm.goodsAlias" placeholder="请填写商品名称" clearable></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button :loading="dialogBtnLoading" size="small" @click="handleClose()">取 消</el-button>
                <el-button :loading="dialogBtnLoading" size="small" type="primary" native-type="submit" @click="dialogSubmit()">确 定</el-button>
            </div>
        </el-dialog>

        <Footer />
    </div>
</template>

<script>
import Header from "@/components/Layout/header.vue";
import Footer from "@/components/Layout/footer.vue";
import LeftHurdle from "@/components/Layout/leftHurdle.vue";

import {
    pcQueryPage,
    externalGoodsUpdate,
    pcExportExternalGoods, 
    externalGoodsBatchUpdate
} from "@/api/api.js"
export default {
    components: { 
        Header,
        Footer,
        LeftHurdle,
    },
    data() {
        return {
            headForm: {
                externalSkuCode: '',
                goodsAlias: '',
                // skuId: '',
                // goodsName: '',
            },
            tableData: [],
            tableLoading: false,
            currentPage: 1,
            pageSize: 20,
            total: 0,
            dialogForm: {
                externalSkuCode: '',
                goodsAlias: '',
            },
            rules: {
                externalSkuCode: [
                    { required: true, message: 'skuId不能为空！', trigger: 'change' },
                    // { pattern: /^[0-9]*$/, message: "请输入数字(不能包含标点符号或空格)" }
                ],
                goodsAlias: [
                    { required: true, message: '名称不能为空！', trigger: 'change' }
                ],
            },
            dialogFormCode: '',
            onExportBtn: false,
            onImportBtn: false,
            importBtnText: '批量导入设置',
            dialogFormVisible: false,
            dialogBtnLoading: false,
        }
    },
    created(){
        this.getGoodsList()
    },
    methods: {
        // 商品状态
        goodsStatusFn(v){
            switch (v) {
                case 0:
                    return '待上架';
                case 1:
                    return '上架中';
                case 2:
                    return '已下架';
            }
        },
        // 查询
        onSeach(){
            console.log('seach');
            this.currentPage = 1
            this.getGoodsList()
        },
        // 导出
        onExport(){
            if(this.onExportBtn)return;
            this.onExportBtn = true
            pcExportExternalGoods(this.headForm).then( res => {
                console.log(res);
                this.onExportBtn = false
            }).catch( err => {
                this.onExportBtn = false
                this.$message.error(err)
            })
        },
        // 批量导入
        onImportChange(){
            this.onImportBtn = true
            this.importBtnText = '导入中···'
            // console.log(this.$refs.importFile.files[0]);
            const formdata = new FormData();
            formdata.set('file', this.$refs.importFile.files[0]);
            externalGoodsBatchUpdate(formdata).then( res => {
                // console.log(res);
                if(res.code == '00000'){
                    this.$message.success('导入成功！')
                    this.$notify({
                        title: '导入成功',
                        type: 'success',
                        dangerouslyUseHTMLString: true,
                        message: res.data,
                        duration: 6000
                    });
                    this.onSeach()
                } else {
                    this.$notify({
                        title: '导入失败！',
                        type: 'warning',
                        dangerouslyUseHTMLString: true,
                        message: res.desc,
                        duration: 10 * 1000
                    });
                }
                this.onImportBtn = false
                this.importBtnText = '批量导入设置'
            }).catch( err => {
                this.onImportBtn = false
                this.importBtnText = '批量导入设置'
                this.$message.error(err)
            })
        },
        // 打开导入
        onImport(){
            if(this.onImportBtn)return;
            // 打开文件选择
            this.$refs.importFile.click()
        },
        // 获取商品列表
        getGoodsList(){
            this.tableLoading = true
            pcQueryPage({
                externalSkuCode: this.headForm.externalSkuCode,
                goodsAlias: this.headForm.goodsAlias,
                // skuId: this.headForm.skuId,
                // goodsName: this.headForm.goodsName,
                "myPage.pageNum": this.currentPage,
                "myPage.pageSize": this.pageSize,
            }).then( res => {
                this.tableLoading = false
                if(res.code == '00000'){
                    this.tableData = res.data.records
                    this.total = res.data.total
                } else {
                    this.$message.error(res.desc)
                }
            })
        },
        // 每页条数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.currentPage = 1
            this.pageSize = val
            this.getGoodsList()
        },
        // 当前页
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.currentPage = val
            this.getGoodsList()
        },
        // 关闭设置商品弹窗
        handleClose() {
            this.dialogForm = {
                externalSkuCode: '',
                goodsAlias: '',
            }
            this.$refs['dialogForm'].resetFields()
            this.dialogFormVisible = false;
        },
        // 设置商品
        setGoodstn(row) {
            // console.log(row);
            this.dialogFormCode = JSON.parse(JSON.stringify(row.externalSkuCode))
            this.dialogForm = {
                externalSkuCode: row.externalSkuCode ?? '',
                goodsAlias: row.goodsAlias ?? '',
                companyId: row.companyId,
                id: row.externalGoodsId,
                skuId: row.skuId,
                goodsId: row.goodsId,
            }
            this.dialogFormVisible = true
            
        },
        // 提交
        dialogSubmit(){
            this.$refs['dialogForm'].validate((valid) => {
                if (valid) {
                    this.dialogBtnLoading = true
                    let data = JSON.parse(JSON.stringify(this.dialogForm))
                    if(this.dialogForm.externalSkuCode == this.dialogFormCode) {
                        data.id = ''
                    }
                    externalGoodsUpdate(data).then( res => {
                        if(res.code == '00000'){
                            this.$message.success('设置成功！')
                            this.dialogFormVisible = false
                            this.getGoodsList()
                        } else {
                            this.$message.error(res.desc)
                        }
                        this.dialogBtnLoading = false
                    }).catch( err =>{
                        this.$message.error(err)
                        this.dialogBtnLoading = false
                    })
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.setGoodsListPage {
    .breadcrumb-box {
        margin-top: 30px;
        padding-bottom: 20px;   
        
        .el-breadcrumb {
            font-size: 18px;
        }
        
    }
    .content-box {
        display: flex;

        .box-rights {
            width: 87%;
            border: 1px solid #d7d7d7;
            border-radius: 10px;

            .box-right-top {
                height: 40px;
                line-height: 40px;
                padding-left: 40px;
                background: #d7d7d7;
                border-bottom: 1px solid #d7d7d7;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                font-weight: bold;
            }


            .from-box {
                padding: 20px 0 0;

                .button {
                    display: flex;
                    align-items: center;

                    div {
                        border-radius: 4px;
                        border: 1px solid #000;
                        text-align: center;
                        width: 60px;
                        height: 30px;
                        line-height: 30px;
                        cursor: pointer;
                        margin-left: 10px;
                    }
                    .search-btn {
                        border-color: #cd9f49;
                        background-color: #cd9f49;
                        color: #fff;

                        &:hover {
                            background-color: #cd9f49e0;
                        }
                    }
                    .export-btn {
                        background-color: #fff;
                        color: #000;
                        font-size: 12px;
                        width: 54px;

                        &:hover {
                            color: #cd9f49;
                            border-color: #cd9f49;
                        }
                    }
                }
            }

            .table-box {
                padding: 0 0 20px;

                .tablePage {
                    display: flex;
                    justify-content: flex-end;
                    padding: 25px;
                    
                }
            }

        }
    }
}
// 分页样式
::v-deep .el-pagination.is-background .el-pager {
    .active {
        background-color: #cd9f49 !important;
    }
    li:hover {
        color: #cd9f49 !important;
    }
    .active:hover {
        color: #fff !important;
    }
}
// 按钮样式
::v-deep .el-button--warning {
    background: #cd9f49;
    border-color: #cd9f49;
    color: #FFF;
    
    &:hover {
        background: #e0b16a;
        border-color: #e0b16a;
    }
}
::v-deep .el-button--warning.is-disabled {
    background: #e0c48e;
    border-color: #e0c48e;
    color: #FFF;
}
</style>

<style lang="scss">
// 分页条数样式
.popperPage {
    .el-scrollbar {
        .el-select-dropdown__item.selected {
            color: #cd9f49;
        }
        .el-select-dropdown__item.hover {
            background-color: #faf9f5;
        }
    }
}
</style>